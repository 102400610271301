import React, { Fragment } from "react";
import Animated from "../components/animated";

const Home = () => {
  return (
    <Animated>
      <Fragment>
        <h1>huku.rocks</h1>
        <h2>coming soon-ish.</h2>
      </Fragment>
    </Animated>
  );
};

export default Home;
